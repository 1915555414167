import { Component, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import {
  AddNewSectionComponent,
  BannerSectionComponent,
  ContactUsComponent,
  FooterSectionComponent,
  ImageCarouselSectionComponent,
  ImageGridSectionComponent,
  ImageSectionComponent,
  NavbarSectionComponent,
  ServiceSectionComponent,
  TeamMemberSectionComponent,
  TestimonialSectionComponent,
  TextImageSectionComponent,
  TextSectionComponent,
  VideoSectionComponent,
} from 'simpo-component-library';

@Injectable({
  providedIn: 'root',
})
export class DynamicPickupService {
  componentsReferences = Array<ComponentRef<any>>();

  listComponents: any = [
    {
      sectionType: 'banner',
      component: BannerSectionComponent,
    },
    {
      sectionType: 'text',
      component: TextSectionComponent,
    },
    {
      sectionType: 'image',
      component: ImageSectionComponent,
    },
    {
      sectionType: 'text_image',
      component: TextImageSectionComponent,
    },
    {
      sectionType: 'service',
      component: ServiceSectionComponent,
    },
    {
      sectionType: 'testimonial',
      component: TestimonialSectionComponent,
    },
    {
      sectionType: 'imagegrid',
      component: ImageGridSectionComponent,
    },
    {
      sectionType: 'imageCarousel',
      component: ImageCarouselSectionComponent,
    },
    {
      sectionType: 'video',
      component: VideoSectionComponent,
    },
    {
      sectionType: 'teammember',
      component: TeamMemberSectionComponent,
    },
    {
      sectionType: 'header',
      component: NavbarSectionComponent,
    },
    {
      sectionType: 'footer',
      component: FooterSectionComponent,
    },
    {
      sectionType: 'addNewSection',
      component: AddNewSectionComponent,
    },
    {
      sectionType: 'contact us',
      component : ContactUsComponent
    }
  ];

  createDynamicComponents(
    displaySection: ViewContainerRef | any,
    sectionList: any[],
    edit: boolean
  ) {
    this.componentsReferences = [];
    displaySection?.clear();
    sectionList?.forEach((section, index) => {
      for (const component of this.listComponents) {
        if (component.sectionType === section.sectionType) {
          const k = displaySection.createComponent(component.component);
          k.instance.data = section;
          k.instance.index = index;
          k.instance.edit = edit;

          this.componentsReferences.push(k);
          break;
        }
      }
    });

    return this.componentsReferences?.[0]?.instance?._eventService || null;
  }
}
