// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseUrl:'https://stage-api.simpo.ai/',
    // baseUrl:'https://stage-api.simpo.ai/',
    baseBusinessUrl :"https://stage-business.simpo.ai/",
  
    scriptSrcFile : "https://durga250720.github.io/blogs/blogs.js",
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  
  
  // its in angular.json 
  
  
  // "outputHashing": "all",
  // "buildOptimizer": true,
  // "optimization": true,
  // "vendorChunk": false,
  // "extractLicenses": true,
  // "sourceMap": false,
  // "namedChunks": false,
  // "aot": true