import { HostListener, Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpService } from './http-service';
import LocalStorage from '../state/local-storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgxUiLoaderModule, NgxUiLoaderService } from "ngx-ui-loader";
import { CommonUtilsService } from './common-utils.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class HomeService implements OnDestroy {
  websiteUrl: string = '';
  currentWebsiteUrl: string = '';
  ssIdParam = 'posese-234wsdez-124wjsuidcx';
  uIdParam = '12jodssd-sdilsd-sdkljs-sekosdfusk';
  catche = new Map<string, any>();
  uniqueId: any;
  sessionId: any;
  source: any;

  constructor(
    private httpService: HttpService,
    private localStorage: LocalStorage,
    private readonly loadingService: NgxUiLoaderService,
    private _commonUtil: CommonUtilsService,
    private deviceService: DeviceDetectorService,
    private http: HttpClient,

  ) {
    if (window.localStorage.getItem("fontFamily") != undefined || window.localStorage.getItem("fontFamily") != null) {
      let data = JSON.parse(window.localStorage.getItem("fontFamily") || "")
      this.setFontFamilies(data);
    }
    this.uniqueId = this._commonUtil.generateUUID();
    if (this.localStorage.get(this.uIdParam)) {
    }
    else {
      this.localStorage.set(this.uIdParam, this.uniqueId)
    }

    if (this.deviceService.isMobile()) {
      this.source = 'MOBILE'
    }
    else if (this.deviceService.isDesktop()) {
      this.source = 'WEB'
    }
  }
  ngOnDestroy(): void {
    // this.endSession(this.sessionId,this.page)
  }


  setWebSiteUrl() {
    this.websiteUrl = window.location.href;
    if (this.websiteUrl.includes('localhost')) {
      this.websiteUrl = 'https://sdsds.dev.simpo.ai/';
    }
    this.currentWebsiteUrl = 'https://' + this.websiteUrl.split('/')[2];
  }

  getWebsiteDetails(): Observable<any> {
    this.loadingService.start('scroll');
    let data = {
      websiteUrl: this.currentWebsiteUrl,
    };
    const data$ = new BehaviorSubject<any>({});
    if (this.catche.has(this.currentWebsiteUrl)) {
      const cachedValue = this.catche.get(this.currentWebsiteUrl);
      setTimeout(() => {
        data$.next(cachedValue);
        this.pageHilighter(cachedValue?.websiteContentV3);
      },);
    } else {
      this.getWebsiteDataByWebsiteUrl(data, this.currentWebsiteUrl).subscribe({
        next: (res: any) => {
          const data = res?.data;
          this.catche.set(this.currentWebsiteUrl, data);
          this.storeWebSiteInfo(data);
          data$.next(data);
          window.localStorage.setItem('fontFamily', JSON.stringify(data?.websiteContentV3));
          this.pageHilighter(data?.websiteContentV3);
          // this.setFontFamilies(data?.websiteContentV3);
        },
        error: (err) => {
          // console.log(err);
          data$.error(err);
        },
      });
      this.loadingService.stop('scroll');
    }

    this.sessionId = this._commonUtil.generateUUID();
    if (this.localStorage.get(this.ssIdParam)) {
    }
    else {
      this.localStorage.set(this.ssIdParam, this.sessionId);
    }
    return data$.asObservable();
  }

  startSession(pageName: any, pageId: any) {
    let url;
    if (window.location.href.includes("localhost")) {
      url = "https://venamuproperty1.dev.simpo.ai/"
    }
    let data = {
      uniqueUserId: this.localStorage.get(this.uIdParam),
      sessionId: this.sessionId,
      pageName: pageName,
      pageId: pageId,
      businessId: window.localStorage.getItem('bId'),
      source: this.source,
      url: url
    }
    const data$ = new BehaviorSubject<any>({});
    if (pageName) {
      this.httpService.post(
        environment.baseBusinessUrl + `analytics/start/session/log`, data).subscribe(
          {
            next: (res) => {
              const data = res?.data;
              data$.next(data);
            },
            error: (err) => {
              // console.log(err);
              data$.error(err);
            }
          }
        );

    }
    return data$.asObservable();
  }

  endSession(pageId: any) {
    let data = {
      sessionId: this.sessionId,
      pageId: pageId
    }
    const data$ = new BehaviorSubject<any>({});
    this.httpService.post(
      environment.baseBusinessUrl + `analytics/end/session/log?sessionId=${data.sessionId}&pageId=${data.pageId}`, data
    ).subscribe(
      {
        next: (res) => {
          const data = res?.data;
          data$.next(data);
        },
        error: (err) => {
          // console.log(err);
          data$.error(err);
        }
      }
    );
    return data$.asObservable();
  }


  // api call
  getWebsiteDataByWebsiteUrl(data: any, websiteUrl: any) {
    return this.httpService.put(
      environment.baseBusinessUrl + `v3/url/data?websiteUrl=${websiteUrl}`,
      data
    );
  }

  getPageDataWithId(id: any): Observable<any> {
    this.loadingService.start('scroll');
    const data$ = new BehaviorSubject<any>({});
    if (this.catche.has(id)) {
      const cachedValue = this.catche.get(id);
      // console.log(cachedValue)
      setTimeout(() => {
        data$.next(cachedValue);
        // console.log(cachedValue)
        // this.pageHilighter(cachedValue)
      }, 0);
    } else {
      this.httpService
        .get(environment.baseBusinessUrl + `v3/page/pageId?pageId=${id}`)
        .subscribe({
          next: (res) => {
            const data = res?.data;
            this.catche.set(id, data);
            data$.next(data);
            // this.pageHilighter(data)
            // this.setFontFamilies(res?.data);
            this.loadingService.stop('scroll');
          },
          error: (err) => {
            this.loadingService.stop('scroll');
            data$.error(err);
          },
        });

      this.sessionId = this._commonUtil.generateUUID();
      if (this.localStorage.get(this.ssIdParam)) {
      }
      else {
        this.localStorage.set(this.ssIdParam, this.sessionId);
      }
    }
    this.loadingService.stop('scroll');

    return data$;
  }

  storeWebSiteInfo(data: any) {
    if (data) {
      this.localStorage.set('websiteData', JSON.stringify(data.websiteContentV3));
      this.localStorage.set('bId', data.website.businessId);
    }
  }

  storeAnalyticsData(data: any) {
    if (data) {
      this.localStorage.set(this.ssIdParam, JSON.stringify(data.ssIdParam));
      this.localStorage.set(this.uIdParam, JSON.stringify(data.uIdParam));
      // console.log(data);
    }
  }



  // Define a method to handle API calls
  saveDataBeforeUnload(data: any): Observable<any> {
    // const url = 'https://your-api-endpoint.com/api/saveData';
    return this.http.post(environment.baseBusinessUrl + `analytics/end/session/log?sessionId=${this.sessionId}&pageId=${data.pageId}`, data, data);
  }

  pageHilighter(data: any) {
    data?.components[0]?.content.navbarButtons.forEach((element: any) => {
      if (element.redirectionUrl === '') {
        element.redirectionUrl = "/"
      }

      if (element.redirectionUrl === window.location.pathname) {
        element.status = true
      }
      else {
        element.status = false
      }
    });
  }


  setFontFamilies(data: any) {

    Object.keys(data?.fonts[0]).forEach((key, index) => {
      const value = data?.fonts[0][key];
      document.documentElement.style.setProperty('--bs-head-font-family', key);
      document.documentElement.style.setProperty('--bs-head-font-weight', value);
    });
    Object.keys(data?.fonts[1]).forEach((key, index) => {
      const value = data?.fonts[0][key];
      document.documentElement.style.setProperty('--bs-body-font-family', key);
      document.documentElement.style.setProperty('--bs-body-font-weight', value);
    })
  }
}
