import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { HomeService } from '../../services/home.service';
import { DynamicPickupService } from '../../services/dynamic-pickup.service';
// import { NavbarRedirectionsService } from '../../services/navbar-redirections.service'
import { NgxUiLoaderModule, NgxUiLoaderService } from "ngx-ui-loader";
import { NavbarRedirectionsService } from './../../services/navbar-redirections.service';
import LocalStorage from '../../state/local-storage';
import { environment } from '../../../environments/environment';
import { CommonButtonRedirectionService } from '../../services/common-button-redirection.service';
import { ContactUsFormService } from '../../services/contact-us-form.service';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '../../services/canonical.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [NgxUiLoaderModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('websiteStructure', { read: ViewContainerRef })
  websiteStructure: ViewContainerRef | any;
  subscription: Array<unknown> = [];
  pageId : any;

  constructor(
    private homeService : HomeService,
    private _pickupService : DynamicPickupService,
    private _navbarRedirections : NavbarRedirectionsService,
    private readonly loadingService: NgxUiLoaderService,
    private metaTagService: Meta,
    private titleService: Title,
    private _commonButtonRedirection : CommonButtonRedirectionService,
    private _contactusFormService : ContactUsFormService,
    private _canonicalService : CanonicalService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.loadingService.start('scroll');
    
    this.homeService.setWebSiteUrl();
    this.homeService.getWebsiteDetails().subscribe({
      next: (data) => {
        // console.log('data', data);
        if(data){
          const instance: any = this._pickupService.createDynamicComponents(
            this.websiteStructure,
            data?.websiteContentV3?.components,
            false
          );
          this.initiateInstance(instance);
          this.startAnalytics(data?.websiteContentV3?.pageName,data?.websiteContentV3?.id);
          this.pageId = data?.websiteContentV3?.id;

          // seo related changes
          this.titleService.setTitle(data?.websiteContentV3?.seoData?.metaTitle);
          this.metaTagService.updateTag({ name: 'description', content: data?.websiteContentV3?.seoData?.metaDescription })
          if(data?.websiteContentV3?.seoData?.keywords != null){
            this.metaTagService.updateTag({ name: 'keywords', content: data?.websiteContentV3?.seoData?.keywords })
          }
          if(data?.websiteContentV3?.seoData?.canonical == "1"){
            this.route.url.subscribe(() => {
              const canonicalURL = this.generateCanonicalURL();
              this._canonicalService.setCanonicalURL(canonicalURL);
          });
          }
          if(data?.websiteContentV3?.seoData?.canonical == "0"){
            this._canonicalService.removeCanonicalURL();
          }

          //set FontFamilies
          // this.setFontFamilies(data);
        }
      },
      error: (err) => {
        // console.log(err);
      },
    });
  }

  generateCanonicalURL(){
     const baseUrl = window.location.origin; // Use the base URL of the application
     const routePath = this.route.snapshot.url.join('/');
     return `${baseUrl}/${routePath}`;
  }

  // setFontFamilies(data:any){
  //   Object.keys(data?.websiteContentV3?.fonts[0]).forEach((key,index)=>{
  //     const value = data?.websiteContentV3.fonts[0][key];
  //     document.documentElement.style.setProperty('--bs-head-font-family', key);
  //     document.documentElement.style.setProperty('--bs-head-font-weight', value);
  //   });

  //   Object.keys(data?.websiteContentV3?.fonts[1]).forEach((key,index)=>{
  //     const value = data?.websiteContentV3.fonts[0][key];
  //     document.documentElement.style.setProperty('--bs-body-font-family',key);
  //     document.documentElement.style.setProperty('--bs-body-font-weight',value);
  //   })
  // }

  

  initiateInstance(instance: any) {
    this.subscription.push(
      instance?.pageRedirectionButton.subscribe((res: any) => {
        // console.log(res)
        this._navbarRedirections.pageRedirection(res.data);
      })
    );
    this.subscription.push(
      instance?.buttonRedirection.subscribe((res:any) => {
        this._commonButtonRedirection.commonButtonRedirection(res.data);
      })
    )
    this.subscription.push(
      instance?.contactFormData.subscribe((res:any) => {
        this._contactusFormService.contactUsData(res.data,res.successMessage);
      })
    )
    this.loadingService.stop('scroll');
  }

  ngOnDestroy() {
    this.subscription = [];
    this.subscription.forEach((element: any) => {
      element.unsubscribe();
    });

    this.endAnalytics();
  }

  startAnalytics(pageName:any,pageId:any){
    // console.log(data);
    this.homeService.startSession(pageName,pageId).subscribe({
      next:(data)=>{
        // console.log(data);
      },
      error:(err) => {
        // console.log(err);
      }
    })
  }

  endAnalytics(){
    this.homeService.endSession(this.pageId).subscribe(
      {
        next:(data) => {
          // console.log()
        },
        error:(err) => {
          // console.log(err)
        }
      }
    )
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: Event): void {
    let data = {
      sessionId: this.homeService.sessionId,
      pageId: this.pageId
    }
    // this.templatesService.endSession(data).subscribe();
    let jsonData = JSON.stringify(data)
    navigator.sendBeacon(environment.baseUrl + `analytics/end/session/log?sessionId=${data.sessionId}&pageId=${data.pageId}`, jsonData)
  }
}
