import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  put(url: string, data: any): Observable<any> {
    const put$ = new BehaviorSubject<any>({});
    this.http.put(url, data).subscribe({
      next: (response) => {
        put$.next(response);
      },
      complete: () => {},
      error: (err) => {
        console.log('possible: global handle err/warning with toast');
        put$.next(err);
      },
    });

    return put$.asObservable();
  }

  get(url: string): Observable<any> {
    const get$ = new BehaviorSubject<any>({});
    this.http.get(url).subscribe({
      next: (response) => {
        get$.next(response);
      },
      complete: () => {},
      error: (err) => {
        console.log('possible: global handle err/warning with toast');
        get$.next(err);
      },
    });

    return get$.asObservable();
  }

  post(url:string, data:any):Observable<any>{
    const post$ = new BehaviorSubject<any>({});
    this.http.post(url,data).subscribe({
      next:(response) => {
        post$.next(response);
      },
      complete : () => {},
      error:(err) => {
        console.log('possible: global handle err/warning with toast');
        post$.next(err);
      }
    });

    return post$.asObservable();
  }
}
