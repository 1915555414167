import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class LocalStorage {
  set(key: string, data: string | object) {
    window.localStorage.setItem(key, data.toString());
  }

  get(key: string) {
    return window.localStorage.getItem(key);
  }
}
